import { Button } from "@my/ui/src/buttons/Button"
import { HealthyLogo } from "app/assets/images/HealthyLogo"
import { FC } from "react"
import { ScrollView, View, useWindowDimensions } from "tamagui"
import { AppFooter } from "app/features/shared/appFooter/AppFooter"
import { ProgramsDetailsPage } from "./ProgramDetails"
import { Program, useAuth } from "@my/api"

interface ProgramsDetailsLandingPageProps {
  renderSeoMetadata?: (program: Program) => React.ReactNode
}

export const ProgramsDetailsLandingPage: FC<ProgramsDetailsLandingPageProps> = ({
  renderSeoMetadata,
}) => {
  const { height: screenHeight } = useWindowDimensions()
  const { isAuthenticated, login } = useAuth()

  return (
    <ScrollView contentContainerStyle={{ minHeight: screenHeight }} position="relative">
      <View backgroundColor={!isAuthenticated ? "$backgroundEmphasis" : undefined}>
        <View
          alignContent="center"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          maxWidth="100%"
          paddingHorizontal="$base"
          marginHorizontal={0}
          paddingVertical={24}
          width="100%"
        >
          <HealthyLogo style={{ height: 44, width: 150 }} />

          {!isAuthenticated && (
            <Button variant="secondary" onPress={() => login()}>
              Login
            </Button>
          )}
        </View>
      </View>

      <View
        paddingVertical={!isAuthenticated ? "$base" : 0}
        flex={1}
        maxWidth="100%"
        margin={0}
        width={1300}
      >
        <ProgramsDetailsPage renderSeoMetadata={renderSeoMetadata} alignment={"left"} />
      </View>

      {!isAuthenticated && <AppFooter />}
    </ScrollView>
  )
}
