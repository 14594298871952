import { PageWithLayout } from "../../base"
import { ProgramsDetailsLandingPage } from "app/features/programs/ProgramsDetailsLandingPage"
import { usePathname } from "next/navigation"
import { ExploreProgramsPage } from "app/features/programs"
import { Helmet } from "react-helmet-async"
import { Program } from "@my/api"
import type { HealthTopicContent, WithContext } from "schema-dts"

function renderSeoMetadata(program: Program) {
  const description = program.seoDescription

  const schema: WithContext<HealthTopicContent> = {
    "@context": "https://schema.org",
    "@type": "HealthTopicContent",
    name: program.name,
    description,
    url: `https://gethealthy.com/${program.path}`,
    mainEntityOfPage: `https://gethealthy.com/${program.path}`,
    author: {
      "@type": "Organization",
      name: "Healthy",
    },
  }

  return (
    <Helmet>
      <title>{program.seoTitle}</title>
      <meta name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

// Shown when user is logged out
const ProgramPage: PageWithLayout = () => {
  const pathname = usePathname()

  // On refresh, pathname is null. We don't want to render anything until it's defined.
  // ProgramDetails will handle an invalid module path.
  if (pathname === null) {
    return <></>
  }

  /*
   * Removes the leading slash from the pathname to get the program path.
   * Example: /ibs/ -> ibs/
   * If this turns out to be an invalid module, ProgramDetails will handle it.
   */
  const path = pathname?.slice(1)

  // If it's not a valid program path, render the programs list
  if (!path) {
    return <ExploreProgramsPage />
  }

  return <ProgramsDetailsLandingPage renderSeoMetadata={renderSeoMetadata} />
}

export default ProgramPage
